import { LoginReq, LoginOutReq, getInfoReq } from '@/api/user'
import {
  setToken,
  removeToken,
  getToken,
  setForWorkOrder,
  setSwitchAccount,
  getSwitchAccount,
  setIsManager,
  setIsWarranty
} from '@/utils/auth'
import router, { asyncRoutes } from '@/router'
import { defineStore } from 'pinia'
import { usePermissionStore } from '@/store/permission'
import { useTagsViewStore } from '@/store/tagsView'
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus'
import { useUserStore } from '@/store/user'
import { removeIsManager, removeIsWarranty } from '@/utils/auth'
import setting from '@/settings'
import { reservelFuncs } from '@/api/reserve'

const resetRouter = () => {
  const asyncRouterNameArr = asyncRoutes.map((mItem) => mItem.name)
  asyncRouterNameArr.forEach((name) => {
    if (router.hasRoute(name)) {
      router.removeRoute(name)
    }
  })
}

export const useAccountStore = defineStore('account', {
  state: () => {
    return {
      // headquarterAccount: '',
      // headquarterPassword : '',
      // managerName: '',
      // roles: [],
      userInfo: {}, //使用者資訊
      reserveSetting: {} //預約設定
    }
  },
  getters: {
    userDetails: (state) => state.userInfo?.data ?? {},
    isOpenReserve: (state) =>
      state.userInfo?.data?.is_reserve && state.reserveSetting?.is_open === '1',
    isAgentOpenReserve: (state) => state.reserveSetting?.is_open === '1',
    isSystemOpenReserve: (state) => state.userInfo?.data?.is_reserve
  },
  actions: {
    M_username(managerName) {
      this.$patch((state) => {
        state.managerName = managerName
      })
    },
    M_roles(roles) {
      console.log('M_roles', roles)
      this.$patch((state) => {
        state.roles = roles
      })
    },
    login(data) {
      // console.log('data')
      // console.log(data)
      return new Promise((resolve, reject) => {
        LoginReq(data)
          .then((res) => {
            // console.log("res success")
            console.log(res)
            if (res.sysCode == 200) {
              // console.log("@")
              setToken(res.data.token)
              setForWorkOrder(
                JSON.stringify({
                  headquarter_id: res.data.headquarterId, //總公司編號
                  branch_id: res.data.branchId, //分公司編號
                  employee_id: res.data.employeeId, //員工編號
                  avatar: res.data.employeeAvatar //員工頭像
                })
              )
              // 支援多個帳號切換
              const _accounts = getSwitchAccount() //取得帳號列表

              const setNew = () => {
                // 寫新的
                const _obj = {}
                _obj[res.data.branchId] = {}
                _obj[res.data.branchId][res.data.employeeId] = {
                  headquarterId: res.data.headquarterId, //總公司編號
                  employeeId: res.data.employeeId, //分公司編號
                  employeeName: res.data.employeeName, //分公司編號
                  avatar: res.data.employeeAvatar, //員工頭像
                  token: res.data.token
                }
                setSwitchAccount(_obj)
              }

              if (_accounts) {
                // 有資料
                const _localBranchId = Object.getOwnPropertyNames(_accounts)[0] //取得其他登入人的branchId

                if (res.data.branchId == _localBranchId) {
                  console.log(_localBranchId)
                  // 檢查現在登入者的店家，跟其他人是否相同，相同就更新
                  _accounts[res.data.branchId][res.data.employeeId] = {
                    headquarterId: res.data.headquarterId, //總公司編號
                    employeeId: res.data.employeeId, //分公司編號
                    employeeName: res.data.employeeName, //分公司編號
                    avatar: res.data.employeeAvatar, //員工頭像
                    token: res.data.token
                  }
                  setSwitchAccount(_accounts)
                } else {
                  // 不同就刪除舊的
                  localStorage.removeItem('switchAccount')
                  setNew()
                }
              } else {
                // 這個裝置尚未有任何帳號登入
                setNew()
              }
              resolve(res)
            } else {
              // console.log("@2")
              reject(res)
            }
          })
          .catch((error) => {
            // console.log("res catch")
            // console.log(error)
            reject(error)
          })
      })
    },
    create(data) {
      // console.log(data)
      return new Promise((resolve, reject) => {
        createAccount(data)
          .then((res) => {
            // console.log("res success")
            // console.log(res)
            if (res.sysCode === 200) {
              // console.log(res)
              //commit('SET_Token', res.data?.jwtToken)
              // setToken(res.data?.authToken)
              // console.log(res.data?.authToken)
              resolve(true)
            } else {
              reject(res)
            }
          })
          .catch((error) => {
            // console.log("res catch")
            reject(error)
          })
      })
    },
    // get user info
    getInfo() {
      return new Promise((resolve, reject) => {
        const userStore = useUserStore()
        getInfoReq()
          .then((res) => {
            // console.log('hello')
            const _isManagerData = JSON.stringify({
              branchManager: res.data.branchManager,
              headquarter_id: res.data.headquarterId, //總公司編號
              branch_id: res.data.branchId, //分公司編號
              employee_id: res.data.employeeId, //員工編號
              employee_name: res.data.employeeName //員工編號
            })
            setIsManager(_isManagerData);
            userStore.isManager = _isManagerData;
            setForWorkOrder(
              JSON.stringify({
                headquarter_id: res.data.headquarterId, //總公司編號
                branch_id: res.data.branchId, //分公司編號
                employee_id: res.data.employeeId //員工編號
              })
            )
            setIsWarranty(JSON.stringify(res.data.is_warranty))
            this.userInfo = res
            console.log('userInfo', this.userInfo)

            this.getReserveSetting()

            resolve(res)
          })
          .catch((error) => {
            // console.log('error', error.sysCode)
            if (error.sysCode == 403) {
              ElMessage({
                message: '請重新登入',
                type: 'error',
                duration: 1000,
                onClose: function () {
                  userStore.resetState().then(() => {
                    router.push({ path: '/login' })
                  })
                }
              })
            }
            reject(error)
          })
      })
    },
    // user logout
    logout() {
      return new Promise((resolve, reject) => {
        // this.resetState()

        LoginOutReq()
          .then((response) => {
            // console.log(response)
            this.M_username('')
            this.M_roles([])
            removeToken() // must remove  token  first
            resetRouter() // reset the router
            removeIsManager() // remove if manager
            removeIsWarranty() // remove if warranty
            const permissionStore = usePermissionStore()
            permissionStore.M_isGetUserInfo(false)
            const tagsViewStore = useTagsViewStore()
            tagsViewStore.delAllViews()
            resolve(response)
          })
          .catch((error) => {
            removeToken() // must remove  token  first
            console.log('error', error)
            reject(error)
          })

        // resolve(null)
      })
    },
    resetState() {
      return new Promise((resolve, reject) => {
        LoginOutReq()
          .then((response) => {
            // console.log(response)
            this.M_username('')
            this.M_roles([])
            removeToken() // must remove  token  first
            resetRouter() // reset the router
            const permissionStore = usePermissionStore()
            permissionStore.M_isGetUserInfo(false)
            const tagsViewStore = useTagsViewStore()
            tagsViewStore.delAllViews()
            resolve(null)
          })
          .catch((error) => {
            console.log('error', error)
            reject(error)
          })
      })
    },
    //取得預約設定
    async getReserveSetting() {
      return new Promise((resolve, reject) => {
        reservelFuncs
          .get()
          .then((res) => {
            this.reserveSetting = res.data
            // console.log('setting', this.reserveSetting)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
})
